/* Article */

.RS-article {
  display: flex;
  flex-flow: column wrap;
  justify-content: space-between;
  max-width: var(--RS-col-6);
  margin: 0 auto;
  text-align: left;

  @media (--lg-viewport) {
    flex-flow: row-reverse wrap;
  }
}

.RS-article + .RS-article{
  margin-top: var(--RS-space-96);
}

.RS-article--block{
  display: block;
}

.RS-article > svg {
  height: auto;
  margin: 0 0 var(--RS-space-48);

  @media (--md-viewport) {
    margin: 0 0 var(--RS-space-64);
  }

  @media (--lg-viewport) {
    margin: 0;
  }
}

.RS-article__artwork {
  margin: 0 0 var(--RS-space-24);
  pointer-events: none;

  @media (--md-viewport) {
    margin: 0 0 var(--RS-space-64);
  }

  @media (--lg-viewport) {
    width: var(--RS-col-2);
    margin: 0;
  }
}

.RS-article__content {
  @media (--lg-viewport) {
    width: var(--RS-col-4);
  }
}

.RS-article__careers-image {
  width: 275px;
  height: 256px;
  margin: 0 auto;

  @media (--sm-viewport) {
    width: 390px;
    height: 363px;
  }

  @media (--md-viewport) {
    width: 430px;
    height: 400px;
  }

  @media (--xlg-viewport) {
    margin: 0 -88px 0 -21px;
  }

}

.RS-article-careers-company .RS-article__careers-image {
  width: 275px;
  height: 256px;
  
  @media (--sm-viewport) {
    width: 390px;
    height: 363px;
  }

  @media (--md-viewport) {
    width: 360px;
    height: 335px;
    margin: 0 auto;
  }

  @media (--xlg-viewport) {
    width: 430px;
    height: 400px;
    margin: 0 -88px 0 -21px;
  }
}


.RS-article__journal-image {
  width: 188px;
  height: 279px;
  margin: 0 auto;

  @media (--sm-viewport) {
    width: 252px;
    height: 374px;
  }

  @media (--md-viewport) {
    width: 270px;
    height: 400px;
  }
}

.RS-article__open-application-image {
  width: 320px;
  height: 384px;
  margin: var(--RS-space-128-n) 0 0 0;
  /* margin-left: 160px; */

  @media (--sm-viewport) {
    width: 420px;
    height: 504px;
    margin-left: 210px;
    margin-right: auto;
  }

  @media (--md-viewport) {
    width: 460px;
    height: 552px;
    margin-left: 230px;
  }

  @media (--lg-viewport) {
    margin-left: unset;
  }

  @media (--xlg-viewport) {
    width: 520px;
    height: 624px;
  }
}

.RS-article__artwork.RS-article__artwork-open-application{
  transform: translateX(-20%);

  @media (--md-viewport) {
    transform: translateX(0%);
  }

  @media (--lg-viewport) {
    transform: translateX(-67%);
  }
}

.RS-game-changers-artwork {
  width: 225px;
  height: 256px;
  margin: 0 auto;

  @media (--md-viewport) {
    width: 352px;
    height: 400px;
  }

  @media (--lg-viewport) {
    margin: 0 auto;
  }
}

.RS-two-partners-one-goal-artwork {
  width: 289px;
  height: 224px;
  margin: 0 auto;

  @media (--sm-viewport) {
    width: 420px;
    height: 317px;
  }

  @media (--md-viewport) {
    width: 464px;
    height: 350px;
  }
}

.RS-article--column-reverse{
  flex-direction: column-reverse
} 


.RS-article--reverse {
  @media (--lg-viewport) {
    flex-flow: row wrap;
  }
}


.RS-article--job-listings{
  flex-flow: column wrap;

  @media (--lg-viewport) {
    flex-flow: row wrap;
  }
}

.RS-article-wide{
  
  max-width: 100%;

  @media (--lg-viewport) {
    max-width: var(--RS-col-8);
  }
}

.RS-article-wide .RS-article__artwork{

  

  @media (--lg-viewport) {
    width: calc(100%/8 * 3);
    max-width: var(--RS-col-3);
  }
}

.RS-article-wide .RS-article__content{

 

  @media (--lg-viewport) {
    width: calc(100%/8 * 5);
    max-width: var(--RS-col-5);
  }
}


.RS-article__artwork-centered{

  justify-content: center;

  @media (--lg-viewport) {
    display: flex;
    align-items: center;
    justify-content: flex-start;
  }
}

.RS-article-careers-company{
  flex-flow: initial;
  flex-direction: column-reverse;
  @media (--lg-viewport) {
    flex-direction: row;
  }
}

.RS-article-careers-company .RS-article__artwork{
  margin: var(--RS-space-48) 0 0 0;
  @media (--lg-viewport) {
    margin: 0 0 var(--RS-space-48) 0;
  }
}


.RS-article--founder-workshops .RS-article__artwork{
  
  align-items: center;
  display: flex;

  @media (--lg-viewport) {
    width: calc(100%/8 * 2);
    max-width: var(--RS-col-2);
  }
}

.RS-article--founder-workshops .RS-article__content{
  @media (--lg-viewport) {
    width: calc(100%/8 * 6);
    max-width: var(--RS-col-6);
  }
}


.RS-article__list{
  width: 100%;
  margin: 0 0 var(--RS-space-24) 0;
  display: flex;
  flex-direction: column;
  gap: var(--RS-space-48);
  padding-left: 0;
  @media (--lg-viewport) {
    padding-left: var(--RS-space-32);
  }
}

.RS-article__list-item{
  display: flex;
  padding: 0 var(--RS-space-8);
  color: var(--RS-light-grey-color);
  position: relative;
  flex-direction: column;
  gap: var(--RS-space-16);
  @media (--md-viewport) {
    padding: var(--RS-space-16);
  }
}

.RS-article__list-item:not(:last-of-type):after{
  content: '';
  height: var(--RS-space-2);
  border-radius: var(--RS-space-2);
  background-color: var(--RS-light-grey-color);
  display: block;
  position: absolute;
  width: 12.5%;
  bottom: calc(var(--RS-space-24-n) + var(--RS-space-1-n));
}

.RS-section-founder-venture-building .RS-article__list-item:not(:last-of-type):after{
  background-color: rgb(252, 205, 202);
}

.RS-section-founder-workshops .RS-article__list-item:not(:last-of-type):after{
  background-color: rgb(252, 250, 212);
}

.RS-article__list-item-qa{
  font-size: var(--RS-font-size-h2);
  line-height: var(--RS-line-height-lg);

  @media (--xlg-viewport) {
    font-size: var(--RS-font-size-h2-desktop);
  }
}

.RS-article__list-item-qa .RS-inline-icon{
  transform: translateY(var(--RS-space-2-n));
}