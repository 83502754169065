/* Header */

.RS-header {
  @media (--lg-viewport) {
    max-width: var(--RS-col-4);
  }
}

.RS-header--centered {
  text-align: center;
}

.RS-header--wide {

  @media (--md-viewport) {
    max-width: var(--RS-col-7);
  }
}

.RS-header--spaced{
  margin: 0 auto var(--RS-space-48);

  @media (--md-viewport) {
    margin: 0 auto var(--RS-space-64);
  }
}

.RS-header--social {
  @media (--md-viewport) {
    max-width: var(--RS-col-6);
  }
}


.RS-header__headline {
  margin: 0 0 var(--RS-space-12);
  font-size: var(--RS-font-size-h3);
  text-transform: uppercase;
  letter-spacing: 0.15em;
  display: inline-flex;

  @media (--xlg-viewport) {
    font-size: var(--RS-font-size-h3);
  }
}

.RS-header__description {
  font-size: var(--RS-font-size-h1);
  line-height: var(--RS-line-height-lg);
  @media (--xlg-viewport) {
    font-size: var(--RS-font-size-h1-desktop);
    line-height: var(--RS-line-height-md);
  }
}

.RS-header__description--centered{
  text-align: center;
}

.RS-header__description + .RS-header__description{
  margin: var(--RS-space-32) 0 0 0;
}

.RS-header__description--intro {
  font-size: var(--RS-font-size-h1-intro);
  font-weight: var(--RS-font-weight-variable-regular);

  @media (--xlg-viewport) {
    font-size: var(--RS-font-size-h1-intro-desktop);
  }
}

.RS-header__description--secondary {
  font-size: var(--RS-font-size-h2);
  line-height: var(--RS-line-height-md);
  font-weight: var(--RS-font-weight-variable-bold);

  @media (--xlg-viewport) {
    font-size: var(--RS-font-size-h2-desktop);
  }
}



.RS-header__headline + .RS-header__description--secondary{
  margin: var(--RS-space-24) 0 0 0;
}

.RS-header__description-link {
  color: inherit;
  position: relative;
  transition: opacity var(--RS-anim-duration-sm) var(--RS-default-transition);
  display: inline-flex;
}

.RS-header__description-link:--enter {
  box-shadow: inset 0 0 0 0 transparent;
  opacity: 1;
}

.RS-header__description-link:before{
  content: '';
  display: block;
  width: 100%;
  position: absolute;
  height: var(--RS-space-2);
  bottom: var(--RS-space-2-n);
  left: 0;
  background-color: var(--RS-pure-white-color);
  transform-origin: left;
  transition: transform var(--RS-anim-duration-xlg) var(--RS-default-transition);
}

.RS-header__description-link:--enter:before{
  transform-origin: right;
  transform: scaleX(0);
  background-color: var(--RS-primary-button-color);
}

.RS-header .RS-header__button {
  margin: var(--RS-space-32) 0 0;

  @media (--md-viewport) {
    margin: var(--RS-space-48) 0 0;
  }
}

.RS-header__description {
  transition-duration: 1s;
}

.RS-header .RS-header__link,
.RS-header__artwork {
  transition-duration: 1.5s;
}

.RS-header-primary-icon > svg{
  display: inline-flex;
  transform: translateY(var(--RS-space-4));
  height: calc(var(--RS-space-32) - var(--RS-space-6));
  width: calc(var(--RS-space-32) - var(--RS-space-6));

  @media (--md-viewport) {
    transform: translateY(var(--RS-space-8));
    height: calc(var(--RS-space-48) - var(--RS-space-6));
    width: calc(var(--RS-space-48) - var(--RS-space-6));
  }
}

.RS-header-secondary-icon{
  transform: translateY(var(--RS-space-6));
  display: inline-flex;
}

.RS-header-secondary-icon > svg{
  height: calc(var(--RS-space-24) + var(--RS-space-2));
  width: calc(var(--RS-space-24) + var(--RS-space-2));

  @media (--md-viewport) {
    height: calc(var(--RS-space-32) + var(--RS-space-2));
    width: calc(var(--RS-space-32) + var(--RS-space-2));
  }
}

.RS-header__tag-wrapper-inner{
  position: absolute;
  left: 0;
  top: calc(var(--RS-space-24-n) - var(--RS-space-2-n));
  width: calc(var(--RS-space-64) + var(--RS-space-24));

  @media (--lg-viewport) {
    left: calc(100% + var(--RS-space-6));
    top: var(--RS-space-2-n);
    width: calc(var(--RS-space-64) + var(--RS-space-32) + var(--RS-space-8));
  }
}


.RS-header__tag-wrapper-inner svg path{
  fill: var(--RS-light-grey-color);
}

.RS-header--newsletter{
  width: 100%;
  
  @media (--lg-viewport) {
    max-width: var(--RS-col-5);
  }
}

.RS-header--newsletter > h1 > span{
  color: var(--RS-the-red-color);
  /* font-weight: var(--RS-font-weight-variable-bold); */
}