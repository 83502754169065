@media only screen and (min-width: 1024px) {
  .RS-header {
    max-width: var(--RS-col-4);
  }
}

.RS-header--centered {
  text-align: center;
}

@media only screen and (min-width: 860px) {
  .RS-header--wide {
    max-width: var(--RS-col-7);
  }
}

.RS-header--spaced {
  margin: 0 auto var(--RS-space-48);
}

@media only screen and (min-width: 860px) {
  .RS-header--spaced {
    margin: 0 auto var(--RS-space-64);
  }

  .RS-header--social {
    max-width: var(--RS-col-6);
  }
}

.RS-header__headline {
  margin: 0 0 var(--RS-space-12);
  font-size: var(--RS-font-size-h3);
  text-transform: uppercase;
  letter-spacing: .15em;
  display: inline-flex;
}

@media only screen and (min-width: 1280px) {
  .RS-header__headline {
    font-size: var(--RS-font-size-h3);
  }
}

.RS-header__description {
  font-size: var(--RS-font-size-h1);
  line-height: var(--RS-line-height-lg);
}

@media only screen and (min-width: 1280px) {
  .RS-header__description {
    font-size: var(--RS-font-size-h1-desktop);
    line-height: var(--RS-line-height-md);
  }
}

.RS-header__description--centered {
  text-align: center;
}

.RS-header__description + .RS-header__description {
  margin: var(--RS-space-32) 0 0 0;
}

.RS-header__description--intro {
  font-size: var(--RS-font-size-h1-intro);
  font-weight: var(--RS-font-weight-variable-regular);
}

@media only screen and (min-width: 1280px) {
  .RS-header__description--intro {
    font-size: var(--RS-font-size-h1-intro-desktop);
  }
}

.RS-header__description--secondary {
  font-size: var(--RS-font-size-h2);
  line-height: var(--RS-line-height-md);
  font-weight: var(--RS-font-weight-variable-bold);
}

@media only screen and (min-width: 1280px) {
  .RS-header__description--secondary {
    font-size: var(--RS-font-size-h2-desktop);
  }
}

.RS-header__headline + .RS-header__description--secondary {
  margin: var(--RS-space-24) 0 0 0;
}

.RS-header__description-link {
  color: inherit;
  transition: opacity var(--RS-anim-duration-sm) var(--RS-default-transition);
  display: inline-flex;
  position: relative;
}

.RS-header__description-link:hover, .RS-header__description-link.focus-visible, .RS-header__description-link:hover, .RS-header__description-link.focus-visible {
  opacity: 1;
  box-shadow: inset 0 0 #0000;
}

.RS-header__description-link:before {
  content: "";
  width: 100%;
  height: var(--RS-space-2);
  bottom: var(--RS-space-2-n);
  background-color: var(--RS-pure-white-color);
  transform-origin: 0;
  transition: transform var(--RS-anim-duration-xlg) var(--RS-default-transition);
  display: block;
  position: absolute;
  left: 0;
}

.RS-header__description-link:hover:before, .RS-header__description-link.focus-visible:before, .RS-header__description-link:hover:before, .RS-header__description-link.focus-visible:before {
  transform-origin: 100%;
  background-color: var(--RS-primary-button-color);
  transform: scaleX(0);
}

.RS-header .RS-header__button {
  margin: var(--RS-space-32) 0 0;
}

@media only screen and (min-width: 860px) {
  .RS-header .RS-header__button {
    margin: var(--RS-space-48) 0 0;
  }
}

.RS-header__description {
  transition-duration: 1s;
}

.RS-header .RS-header__link, .RS-header__artwork {
  transition-duration: 1.5s;
}

.RS-header-primary-icon > svg {
  transform: translateY(var(--RS-space-4));
  height: calc(var(--RS-space-32)  - var(--RS-space-6));
  width: calc(var(--RS-space-32)  - var(--RS-space-6));
  display: inline-flex;
}

@media only screen and (min-width: 860px) {
  .RS-header-primary-icon > svg {
    transform: translateY(var(--RS-space-8));
    height: calc(var(--RS-space-48)  - var(--RS-space-6));
    width: calc(var(--RS-space-48)  - var(--RS-space-6));
  }
}

.RS-header-secondary-icon {
  transform: translateY(var(--RS-space-6));
  display: inline-flex;
}

.RS-header-secondary-icon > svg {
  height: calc(var(--RS-space-24)  + var(--RS-space-2));
  width: calc(var(--RS-space-24)  + var(--RS-space-2));
}

@media only screen and (min-width: 860px) {
  .RS-header-secondary-icon > svg {
    height: calc(var(--RS-space-32)  + var(--RS-space-2));
    width: calc(var(--RS-space-32)  + var(--RS-space-2));
  }
}

.RS-header__tag-wrapper-inner {
  left: 0;
  top: calc(var(--RS-space-24-n)  - var(--RS-space-2-n));
  width: calc(var(--RS-space-64)  + var(--RS-space-24));
  position: absolute;
}

@media only screen and (min-width: 1024px) {
  .RS-header__tag-wrapper-inner {
    left: calc(100% + var(--RS-space-6));
    top: var(--RS-space-2-n);
    width: calc(var(--RS-space-64)  + var(--RS-space-32)  + var(--RS-space-8));
  }
}

.RS-header__tag-wrapper-inner svg path {
  fill: var(--RS-light-grey-color);
}

.RS-header--newsletter {
  width: 100%;
}

@media only screen and (min-width: 1024px) {
  .RS-header--newsletter {
    max-width: var(--RS-col-5);
  }
}

.RS-header--newsletter > h1 > span {
  color: var(--RS-the-red-color);
}

.RS-article {
  max-width: var(--RS-col-6);
  text-align: left;
  flex-flow: column wrap;
  justify-content: space-between;
  margin: 0 auto;
  display: flex;
}

@media only screen and (min-width: 1024px) {
  .RS-article {
    flex-flow: row-reverse wrap;
  }
}

.RS-article + .RS-article {
  margin-top: var(--RS-space-96);
}

.RS-article--block {
  display: block;
}

.RS-article > svg {
  height: auto;
  margin: 0 0 var(--RS-space-48);
}

@media only screen and (min-width: 860px) {
  .RS-article > svg {
    margin: 0 0 var(--RS-space-64);
  }
}

@media only screen and (min-width: 1024px) {
  .RS-article > svg {
    margin: 0;
  }
}

.RS-article__artwork {
  margin: 0 0 var(--RS-space-24);
  pointer-events: none;
}

@media only screen and (min-width: 860px) {
  .RS-article__artwork {
    margin: 0 0 var(--RS-space-64);
  }
}

@media only screen and (min-width: 1024px) {
  .RS-article__artwork {
    width: var(--RS-col-2);
    margin: 0;
  }

  .RS-article__content {
    width: var(--RS-col-4);
  }
}

.RS-article__careers-image {
  width: 275px;
  height: 256px;
  margin: 0 auto;
}

@media only screen and (min-width: 480px) {
  .RS-article__careers-image {
    width: 390px;
    height: 363px;
  }
}

@media only screen and (min-width: 860px) {
  .RS-article__careers-image {
    width: 430px;
    height: 400px;
  }
}

@media only screen and (min-width: 1280px) {
  .RS-article__careers-image {
    margin: 0 -88px 0 -21px;
  }
}

.RS-article-careers-company .RS-article__careers-image {
  width: 275px;
  height: 256px;
}

@media only screen and (min-width: 480px) {
  .RS-article-careers-company .RS-article__careers-image {
    width: 390px;
    height: 363px;
  }
}

@media only screen and (min-width: 860px) {
  .RS-article-careers-company .RS-article__careers-image {
    width: 360px;
    height: 335px;
    margin: 0 auto;
  }
}

@media only screen and (min-width: 1280px) {
  .RS-article-careers-company .RS-article__careers-image {
    width: 430px;
    height: 400px;
    margin: 0 -88px 0 -21px;
  }
}

.RS-article__journal-image {
  width: 188px;
  height: 279px;
  margin: 0 auto;
}

@media only screen and (min-width: 480px) {
  .RS-article__journal-image {
    width: 252px;
    height: 374px;
  }
}

@media only screen and (min-width: 860px) {
  .RS-article__journal-image {
    width: 270px;
    height: 400px;
  }
}

.RS-article__open-application-image {
  width: 320px;
  height: 384px;
  margin: var(--RS-space-128-n) 0 0 0;
}

@media only screen and (min-width: 480px) {
  .RS-article__open-application-image {
    width: 420px;
    height: 504px;
    margin-left: 210px;
    margin-right: auto;
  }
}

@media only screen and (min-width: 860px) {
  .RS-article__open-application-image {
    width: 460px;
    height: 552px;
    margin-left: 230px;
  }
}

@media only screen and (min-width: 1024px) {
  .RS-article__open-application-image {
    margin-left: 0;
    margin-left: initial;
  }
}

@media only screen and (min-width: 1280px) {
  .RS-article__open-application-image {
    width: 520px;
    height: 624px;
  }
}

.RS-article__artwork.RS-article__artwork-open-application {
  transform: translateX(-20%);
}

@media only screen and (min-width: 860px) {
  .RS-article__artwork.RS-article__artwork-open-application {
    transform: translateX(0%);
  }
}

@media only screen and (min-width: 1024px) {
  .RS-article__artwork.RS-article__artwork-open-application {
    transform: translateX(-67%);
  }
}

.RS-game-changers-artwork {
  width: 225px;
  height: 256px;
  margin: 0 auto;
}

@media only screen and (min-width: 860px) {
  .RS-game-changers-artwork {
    width: 352px;
    height: 400px;
  }
}

@media only screen and (min-width: 1024px) {
  .RS-game-changers-artwork {
    margin: 0 auto;
  }
}

.RS-two-partners-one-goal-artwork {
  width: 289px;
  height: 224px;
  margin: 0 auto;
}

@media only screen and (min-width: 480px) {
  .RS-two-partners-one-goal-artwork {
    width: 420px;
    height: 317px;
  }
}

@media only screen and (min-width: 860px) {
  .RS-two-partners-one-goal-artwork {
    width: 464px;
    height: 350px;
  }
}

.RS-article--column-reverse {
  flex-direction: column-reverse;
}

@media only screen and (min-width: 1024px) {
  .RS-article--reverse {
    flex-flow: wrap;
  }
}

.RS-article--job-listings {
  flex-flow: column wrap;
}

@media only screen and (min-width: 1024px) {
  .RS-article--job-listings {
    flex-flow: wrap;
  }
}

.RS-article-wide {
  max-width: 100%;
}

@media only screen and (min-width: 1024px) {
  .RS-article-wide {
    max-width: var(--RS-col-8);
  }

  .RS-article-wide .RS-article__artwork {
    width: 37.5%;
    max-width: var(--RS-col-3);
  }

  .RS-article-wide .RS-article__content {
    width: 62.5%;
    max-width: var(--RS-col-5);
  }
}

.RS-article__artwork-centered {
  justify-content: center;
}

@media only screen and (min-width: 1024px) {
  .RS-article__artwork-centered {
    justify-content: flex-start;
    align-items: center;
    display: flex;
  }
}

.RS-article-careers-company {
  flex-flow: initial;
  flex-direction: column-reverse;
}

@media only screen and (min-width: 1024px) {
  .RS-article-careers-company {
    flex-direction: row;
  }
}

.RS-article-careers-company .RS-article__artwork {
  margin: var(--RS-space-48) 0 0 0;
}

@media only screen and (min-width: 1024px) {
  .RS-article-careers-company .RS-article__artwork {
    margin: 0 0 var(--RS-space-48) 0;
  }
}

.RS-article--founder-workshops .RS-article__artwork {
  align-items: center;
  display: flex;
}

@media only screen and (min-width: 1024px) {
  .RS-article--founder-workshops .RS-article__artwork {
    width: 25%;
    max-width: var(--RS-col-2);
  }

  .RS-article--founder-workshops .RS-article__content {
    width: 75%;
    max-width: var(--RS-col-6);
  }
}

.RS-article__list {
  width: 100%;
  margin: 0 0 var(--RS-space-24) 0;
  gap: var(--RS-space-48);
  flex-direction: column;
  padding-left: 0;
  display: flex;
}

@media only screen and (min-width: 1024px) {
  .RS-article__list {
    padding-left: var(--RS-space-32);
  }
}

.RS-article__list-item {
  padding: 0 var(--RS-space-8);
  color: var(--RS-light-grey-color);
  gap: var(--RS-space-16);
  flex-direction: column;
  display: flex;
  position: relative;
}

@media only screen and (min-width: 860px) {
  .RS-article__list-item {
    padding: var(--RS-space-16);
  }
}

.RS-article__list-item:not(:last-of-type):after {
  content: "";
  height: var(--RS-space-2);
  border-radius: var(--RS-space-2);
  background-color: var(--RS-light-grey-color);
  width: 12.5%;
  bottom: calc(var(--RS-space-24-n)  + var(--RS-space-1-n));
  display: block;
  position: absolute;
}

.RS-section-founder-venture-building .RS-article__list-item:not(:last-of-type):after {
  background-color: #fccdca;
}

.RS-section-founder-workshops .RS-article__list-item:not(:last-of-type):after {
  background-color: #fcfad4;
}

.RS-article__list-item-qa {
  font-size: var(--RS-font-size-h2);
  line-height: var(--RS-line-height-lg);
}

@media only screen and (min-width: 1280px) {
  .RS-article__list-item-qa {
    font-size: var(--RS-font-size-h2-desktop);
  }
}

.RS-article__list-item-qa .RS-inline-icon {
  transform: translateY(var(--RS-space-2-n));
}

.RS-intro {
  z-index: 0;
  padding: 12vh var(--RS-space-24) 4vh;
  height: 90vh;
  align-items: flex-end;
  display: flex;
  position: relative;
}

@media only screen and (max-height: 430px) and (max-width: 1024px) {
  .RS-intro {
    height: auto;
    min-height: 100vh;
  }
}

@media only screen and (min-width: 1024px) {
  .RS-intro {
    height: 100vh;
    min-height: 0;
    min-height: initial;
    padding: 14vh var(--RS-space-32) 8vh;
    margin: 0 0 var(--RS-space-32);
  }
}

.RS-intro-careers {
  height: auto;
  min-height: 90vh;
  --RS-orbit-xy: calc(var(--RS-space-256)  + var(--RS-space-32));
  --RS-background-step: 10vh;
}

@media only screen and (min-width: 480px) {
  .RS-intro-careers {
    --RS-orbit-xy: calc(var(--RS-space-256)  + var(--RS-space-128)  + var(--RS-space-32));
  }
}

@media only screen and (min-width: 860px) {
  .RS-intro-careers {
    --RS-orbit-xy: calc(100% + var(--RS-space-32) * 2);
    --RS-background-step: 25vh;
  }
}

@media only screen and (min-width: 1024px) {
  .RS-intro-careers {
    height: 100vh;
    min-height: 0;
    min-height: initial;
  }
}

.RS-intro-founder {
  padding: 8vh var(--RS-space-32) 8vh;
}

@media only screen and (min-width: 1024px) {
  .RS-intro-founder {
    padding: 8vh var(--RS-space-32) 8vh;
  }
}

.RS-intro--thank-you {
  height: auto;
  min-height: 75vh;
  align-items: flex-start;
}

.RS-intro__container {
  z-index: 1;
  width: 100%;
  max-width: var(--RS-col-8);
  min-height: 100%;
  height: 100%;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 0 auto;
  display: flex;
  position: relative;
}

@media only screen and (min-width: 1024px) {
  .RS-intro__container {
    flex-direction: row-reverse;
  }
}

.RS-intro__container--founder {
  align-items: center;
}

.RS-intro__content {
  width: 100%;
  margin-right: auto;
}

@media only screen and (min-width: 1024px) {
  .RS-intro__content {
    max-width: var(--RS-col-4);
    max-width: 50%;
    margin-right: 0;
    margin-right: initial;
  }
}

.RS-intro__container--founder .RS-intro__content {
  flex-direction: column;
  display: flex;
}

@media only screen and (min-width: 1024px) {
  .RS-intro__container--founder .RS-intro__content {
    max-width: 100%;
  }
}

.RS-intro__container--thank-you .RS-intro__content {
  max-width: 100%;
}

.RS-intro__artwork {
  width: 100%;
  z-index: -1;
  height: 100%;
  pointer-events: none;
  position: relative;
  transform: translateY(0);
}

@media only screen and (min-width: 480px) {
  .RS-intro__artwork {
    width: 520px;
  }
}

@media only screen and (min-width: 860px) {
  .RS-intro__artwork {
    width: 564px;
    max-width: 80%;
    margin-left: auto;
  }
}

@media only screen and (min-width: 1024px) {
  .RS-intro__artwork {
    width: 508px;
    margin: 0;
  }
}

@media only screen and (min-width: 1280px) {
  .RS-intro__artwork {
    width: 642px;
  }
}

@media only screen and (min-width: 1920px) {
  .RS-intro__artwork {
    transform: translateY(5vh);
  }
}

@media only screen and (max-height: 430px) and (max-width: 1024px) {
  .RS-intro__artwork {
    width: 46%;
  }
}

.RS-intro__container--founder .RS-intro__artwork {
  width: 100%;
  max-width: 100%;
  transform: none;
  transform: initial;
  justify-content: center;
  align-items: center;
  display: flex;
  position: absolute;
}

.RS-intro__artwork--industry {
  transform: none;
}

.RS-intro__headline {
  margin: 0 0 var(--RS-space-6) 0;
  font-size: var(--RS-font-size-h3);
  line-height: var(--RS-line-height-xlg);
  letter-spacing: .075em;
}

@media only screen and (min-width: 860px) {
  .RS-intro__headline {
    margin: 0 0 var(--RS-space-16) 0;
  }
}

.RS-intro__title {
  font-size: var(--RS-font-size-h1-intro);
  line-height: var(--RS-line-height-md);
  margin: 3vh 0;
  transition-duration: 1s;
}

@media only screen and (min-width: 860px) {
  .RS-intro__title {
    margin: 6vh 0;
  }
}

@media only screen and (min-width: 1024px) {
  .RS-intro__title {
    font-size: var(--RS-font-size-h1-intro-desktop);
  }
}

.RS-intro__container--founder .RS-intro__title {
  text-align: center;
  margin: 2vh 0;
}

@media only screen and (min-width: 860px) {
  .RS-intro__container--founder .RS-intro__title {
    margin: 4vh 0;
  }
}

.RS-intro__job-promo, .RS-intro__artwork > svg {
  width: auto;
  transition-duration: 1.5s;
}

.RS-intro__container--founder .RS-intro__job-promo {
  margin: 0 auto;
}

.RS-intro-artwork__bicycle .RS-animation__artwork-shadow {
  animation-duration: 8s;
  transform: translate(-8px, 21px);
}

.RS-intro-artwork__bicycle .RS-animation__artwork-content {
  animation-duration: 8s;
}

.RS-intro__artwork-inner {
  width: 100%;
  height: inherit;
  position: absolute;
}

.RS-intro__artwork > .RS-animation {
  height: inherit;
}

.RS-intro__description {
  font-size: var(--RS-font-size-h1);
  line-height: var(--RS-line-height-lg);
  margin: 0 0 6vh;
}

@media only screen and (min-width: 1280px) {
  .RS-intro__description {
    font-size: var(--RS-font-size-h1-desktop);
    line-height: var(--RS-line-height-md);
  }
}

.RS-intro-careers__container {
  position: initial;
  flex-direction: column-reverse;
  justify-content: flex-end;
  position: static;
}

@media only screen and (min-width: 1024px) {
  .RS-intro-careers__container {
    flex-direction: row-reverse;
  }
}

.RS-intro__photos {
  padding-top: var(--RS-space-32);
  width: 100%;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  display: flex;
  position: relative;
}

@media only screen and (min-width: 480px) {
  .RS-intro__photos {
    gap: var(--RS-space-32);
  }
}

@media only screen and (min-width: 1024px) {
  .RS-intro__photos {
    height: 100%;
    width: calc(50vw + var(--RS-space-16));
    top: 0;
    right: var(--RS-space-24-n);
    position: absolute;
  }
}

.RS-intro__photos-col {
  align-items: flex-end;
  gap: var(--RS-space-32);
  flex-direction: column;
  display: flex;
  position: relative;
}

.RS-intro__photos img {
  border-radius: var(--RS-space-12);
  margin: 0 auto;
}

.RS-intro__photo-vertical {
  transform: translate3d(0px, 0px, 0px) rotate(var(--RS-rotate-firefox-fix));
  display: none;
}

@media only screen and (min-width: 480px) {
  .RS-intro__photo-vertical {
    display: inline;
    display: initial;
    animation: animImageVertical 5.5s var(--RS-default-transition) infinite;
  }
}

@keyframes animImageVertical {
  0%, 100% {
    transform: translate3d(0px, 0px, 0px) rotate(var(--RS-rotate-firefox-fix));
  }

  50% {
    transform: translate3d(-3px, 0px, 0px) rotate(var(--RS-rotate-firefox-fix));
  }
}

.RS-intro__photo-horizontal {
  transform-origin: 100% 100%;
  transform: translate3d(0px, 0px, 0px) rotate(var(--RS-rotate-firefox-fix));
  animation-delay: calc(var(--RS-anim-delay) * 2);
  outline: 1px solid #0000;
}

@media only screen and (min-width: 480px) {
  .RS-intro__photo-horizontal {
    display: inline;
    display: initial;
    animation: animImageHorizontal 5.5s var(--RS-default-transition) infinite;
  }
}

@keyframes animImageHorizontal {
  0%, 100% {
    transform: translate3d(0px, 0px, 0px) rotate(var(--RS-rotate-firefox-fix));
  }

  50% {
    transform: translate3d(-3px, -3px, 0px) rotate(var(--RS-rotate-firefox-fix));
  }
}

.RS-intro__photo-square {
  max-width: 50%;
  transform-origin: 100% 0;
  transform: translate3d(0px, 0px, 0px) rotate(var(--RS-rotate-firefox-fix));
  animation-delay: calc(var(--RS-anim-delay) * 4);
  display: none;
}

@media only screen and (min-width: 480px) {
  .RS-intro__photo-square {
    display: inline;
    display: initial;
    animation: animImageSquare 5.5s var(--RS-default-transition) infinite;
  }
}

@keyframes animImageSquare {
  0%, 100% {
    transform: translate3d(0px, 0px, 0px) rotate(var(--RS-rotate-firefox-fix));
  }

  50% {
    transform: translate3d(-3px, 3px, 0px) rotate(var(--RS-rotate-firefox-fix));
  }
}

.RS-intro__photo-square img {
  aspect-ratio: 1 / 1;
}

.RS-intro__photo-vertical img {
  aspect-ratio: .665 / 1;
}

.RS-intro__photo-horizontal img {
  aspect-ratio: 1.5 / 1;
}

.RS-intro__photos-background {
  width: calc(200% + var(--RS-space-64));
  height: calc(100% + var(--RS-space-32) * 2);
  right: 0;
  top: var(--RS-space-32-n);
  background: var(--RS-space-grey-color);
  z-index: -1;
  border-radius: var(--RS-space-24);
  display: none;
  position: absolute;
}

@media only screen and (min-width: 480px) {
  .RS-intro__photos-background {
    display: inline;
    display: initial;
  }
}

.RS-image-glitch-overlay {
  position: absolute;
  top: 0;
}

.RS-image-glitch-overlay--first {
  transform-origin: 0 100%;
  mix-blend-mode: lighten;
  opacity: 0;
  animation: 8s linear infinite alternate animImageGlitchOverlayFirst;
  transform: translate3d(0, 0, 0)scale(.96);
}

@keyframes animImageGlitchOverlayFirst {
  0%, 34%, 36%, 66.5%, 68.5%, 100% {
    opacity: 0;
    transform: translate3d(0, 0, 0)scale(.8);
  }

  35%, 67.5% {
    opacity: 1;
    transform: translate3d(-5px, 4px, 0)scale(.8);
  }
}

.RS-image-glitch-overlay--second {
  transform-origin: 100% 0;
  mix-blend-mode: darken;
  opacity: 0;
  animation: 8s linear infinite alternate animImageGlitchOverlaySecond;
  transform: translate3d(0, 0, 0)scale(.96);
}

@keyframes animImageGlitchOverlaySecond {
  0%, 34%, 36%, 66.5%, 68.5%, 100% {
    opacity: 0;
    transform: translate3d(0, 0, 0)scale(.8);
  }

  35%, 67.5% {
    opacity: 1;
    transform: translate3d(5px, -4px, 0)scale(.8);
  }
}

.RS-image-glitch-overlay--vertical-top {
  -webkit-clip-path: polygon(0 0, 0% 100%, 100% 100%);
  clip-path: polygon(0 0, 0% 100%, 100% 100%);
  animation-delay: calc(var(--RS-anim-delay) * 0);
}

.RS-image-glitch-overlay--vertical-bottom {
  -webkit-clip-path: polygon(100% 0, 0 0, 100% 100%);
  clip-path: polygon(100% 0, 0 0, 100% 100%);
  animation-delay: calc(var(--RS-anim-delay) * 2);
}

.RS-image-glitch-overlay--horizontal-top {
  -webkit-clip-path: polygon(0 0, 100% 0, 100% 33%, 0 33%);
  clip-path: polygon(0 0, 100% 0, 100% 33%, 0 33%);
  animation-delay: calc(var(--RS-anim-delay) * 4);
}

.RS-image-glitch-overlay--horizontal-bottom {
  -webkit-clip-path: polygon(0 67%, 100% 67%, 100% 100%, 0 100%);
  clip-path: polygon(0 67%, 100% 67%, 100% 100%, 0 100%);
  animation-delay: calc(var(--RS-anim-delay) * 6);
}

.RS-image-glitch-overlay--square-left {
  -webkit-clip-path: polygon(0 0, 33% 0, 33% 100%, 0 100%);
  clip-path: polygon(0 0, 33% 0, 33% 100%, 0 100%);
  animation-delay: calc(var(--RS-anim-delay) * 8);
}

.RS-image-glitch-overlay--square-right {
  -webkit-clip-path: polygon(67% 0, 100% 0, 100% 100%, 67% 100%);
  clip-path: polygon(67% 0, 100% 0, 100% 100%, 67% 100%);
  animation-delay: calc(var(--RS-anim-delay) * 10);
}

.RS-intro-careers .RS-background__logo {
  left: calc(0% - var(--RS-orbit-xy) / 2);
  top: calc(50% - var(--RS-orbit-xy) / 4);
  mix-blend-mode: soft-light;
  display: none;
}

@media only screen and (min-width: 480px) {
  .RS-intro-careers .RS-background__logo {
    display: inline;
    display: initial;
  }
}

.RS-intro-careers .RS-inner-background-logo {
  opacity: 1;
  animation: 27.5s linear infinite animImageLogo;
}

@keyframes animImageLogo {
  0% {
    transform: rotateZ(0);
  }

  100% {
    transform: rotateZ(-360deg);
  }
}

.RS-intro__text-glitch {
  transform: translate3d(0, 0, 0)skew(0deg);
}

@keyframes animTextGlitch {
  2%, 64% {
    transform: translate3d(2px, 0, 0)skew(0deg);
  }

  4%, 60% {
    transform: translate3d(-2px, 0, 0)skew(0deg);
  }

  62% {
    transform: translate3d(0, 0, 0)skew(5deg);
  }
}

.RS-intro__text-glitch:before, .RS-intro__text-glitch:after {
  content: attr(title);
  position: absolute;
  left: 0;
  transform: translate3d(0, 0, 0)skew(0deg);
}

.RS-intro__text-glitch:before {
  -webkit-clip-path: polygon(0 0, 100% 0, 100% 33%, 0 33%);
  clip-path: polygon(0 0, 100% 0, 100% 33%, 0 33%);
  animation: 1s linear infinite animTextGlitchTop;
}

@keyframes animTextGlitchTop {
  60%, 64% {
    transform: translate3d(0, 0, 0);
  }

  62% {
    transform: translate3d(13px, -1px, 0)skew(-13deg);
  }
}

.RS-intro__text-glitch:after {
  -webkit-clip-path: polygon(0 67%, 100% 67%, 100% 100%, 0 100%);
  clip-path: polygon(0 67%, 100% 67%, 100% 100%, 0 100%);
  animation: 1.5s linear infinite animTextGlitchBottom;
}

@keyframes animTextGlitchBottom {
  60%, 64% {
    transform: translate3d(0, 0, 0);
  }

  62% {
    transform: translate3d(-22px, 5px, 0)skew(21deg);
  }
}

.RS-intro-founder-background {
  width: inherit;
  height: inherit;
  top: auto;
  top: initial;
  left: auto;
  left: initial;
  z-index: -1;
  --RS-orbit-xy: calc(var(--RS-space-256)  + var(--RS-space-128));
  --RS-background-step: 20vh;
  position: absolute;
}

@media only screen and (min-width: 480px) {
  .RS-intro-founder-background {
    --RS-orbit-xy: calc(var(--RS-space-256)  + var(--RS-space-256));
    --RS-background-step: 25vh;
  }
}

@media only screen and (min-width: 860px) {
  .RS-intro-founder-background {
    --RS-orbit-xy: calc(var(--RS-space-256)  + var(--RS-space-256)  + var(--RS-space-64));
    --RS-background-step: 30vh;
  }
}

.RS-intro-founder-background-inner {
  width: inherit;
  height: inherit;
  justify-content: center;
  align-items: center;
  display: flex;
}

.RS-intro-founder-background-logo {
  backface-visibility: hidden;
  width: calc(var(--RS-orbit-xy) * .25);
  height: calc(var(--RS-orbit-xy) * .25);
  z-index: 2;
  position: absolute;
}

@media only screen and (min-width: 860px) {
  .RS-intro-founder-background-logo {
    width: calc(var(--RS-orbit-xy) * .225);
    height: calc(var(--RS-orbit-xy) * .225);
  }
}

.RS-intro-founder-background-logo:nth-of-type(1) {
  top: 4%;
  left: 4%;
}

.RS-intro-founder-background-logo:nth-of-type(2) {
  bottom: 4%;
  right: 4%;
}

.RS-intro-founder-background-icon {
  backface-visibility: hidden;
  width: calc(var(--RS-orbit-xy) * .05);
  height: calc(var(--RS-orbit-xy) * .05);
  z-index: 2;
  position: absolute;
}

@media only screen and (min-width: 860px) {
  .RS-intro-founder-background-icon {
    width: calc(var(--RS-orbit-xy) * .05);
    height: calc(var(--RS-orbit-xy) * .05);
  }
}

.RS-intro-founder-background-icon:nth-of-type(1) {
  top: 12.75%;
  left: 12.75%;
}

.RS-intro-founder-background-orbit:nth-of-type(3) > .RS-intro-founder-background-icon:nth-of-type(1) {
  top: 50%;
  left: -1.5%;
}

.RS-intro-founder-background-icon:nth-of-type(2) {
  bottom: 12.75%;
  right: 12.75%;
}

.RS-intro-founder-background-orbit:nth-of-type(3) > .RS-intro-founder-background-icon:nth-of-type(2) {
  top: 50%;
  right: -1.5%;
}

.RS-intro-founder-background-icon:nth-of-type(3) {
  bottom: 12.75%;
  left: 12.75%;
}

.RS-intro-founder-background-icon:nth-of-type(4) {
  top: 12.75%;
  right: 12.75%;
}

.RS-intro-founder-background-logo:before, .RS-intro-founder-background-icon:before {
  content: "";
  width: 125%;
  height: 125%;
  background: var(--RS-space-black-color);
  border-radius: 50%;
  display: block;
  position: absolute;
  top: -12.5%;
  left: -12.5%;
}

.RS-intro-founder-background-icon:before {
  width: 200%;
  height: 200%;
  top: -50%;
  left: -50%;
}

.RS-intro-founder-background-logo-inner, .RS-intro-founder-background-icon-inner {
  opacity: .21;
  z-index: 1;
  position: relative;
  overflow: visible;
}

.RS-intro-founder-background-logo:nth-of-type(2) > .RS-intro-founder-background-logo-inner {
  animation-delay: 5s;
  transform: translate(5%, -5%);
}

.RS-intro-founder-background-icon:nth-of-type(2) > .RS-intro-founder-background-icon-inner {
  animation-delay: 1.25s;
}

.RS-intro-founder-background-icon:nth-of-type(3) > .RS-intro-founder-background-icon-inner {
  animation-delay: 2.5s;
  transform: translate(10%);
}

.RS-intro-founder-background-icon:nth-of-type(4) > .RS-intro-founder-background-icon-inner {
  animation-delay: 3.75s;
  transform: translate(-10%, -10%);
}

.RS-intro-founder-background-orbit {
  width: var(--RS-orbit-xy);
  height: var(--RS-orbit-xy);
  opacity: 1;
  transition: transform var(--RS-anim-duration-lg) var(--RS-default-transition), opacity var(--RS-anim-duration-md) var(--RS-default-transition);
  backface-visibility: hidden;
  pointer-events: none;
  -webkit-user-select: none;
  user-select: none;
  border: 2px solid #ffffff36;
  border-radius: 50%;
  position: absolute;
}

@media only screen and (min-width: 860px) {
  .RS-intro-founder-background-orbit {
    opacity: 1;
  }
}

.RS-intro-founder-background-orbit:nth-of-type(2) {
  width: calc(var(--RS-orbit-xy)  + var(--RS-background-step) * 1);
  height: calc(var(--RS-orbit-xy)  + var(--RS-background-step) * 1);
  top: auto;
  top: initial;
  left: auto;
  left: initial;
}

.RS-intro-founder-background-orbit:nth-of-type(3) {
  width: calc(var(--RS-orbit-xy)  + var(--RS-background-step) * 1.66);
  height: calc(var(--RS-orbit-xy)  + var(--RS-background-step) * 1.66);
  top: auto;
  top: initial;
  left: auto;
  left: initial;
  border: none;
  border: initial;
}

.RS-intro--newsletter {
  height: auto;
  min-height: 90vh;
}

@media only screen and (max-height: 430px) and (max-width: 1024px) {
  .RS-intro--newsletter {
    height: auto;
    min-height: 100vh;
  }
}

@media only screen and (min-width: 1024px) {
  .RS-intro--newsletter {
    height: auto;
    min-height: 0;
    min-height: initial;
    padding: 14vh var(--RS-space-32) 8vh;
    margin: 0 0 var(--RS-space-32);
  }
}

.RS-intro--newsletter__container {
  justify-content: space-between;
}

.RS-intro--newsletter__content {
  width: 100%;
}

@media only screen and (min-width: 1024px) {
  .RS-intro--newsletter__content {
    max-width: var(--RS-col-5);
  }
}

.RS-intro--newsletter__artwork {
  width: 280px;
  max-width: 60%;
  z-index: -1;
  height: 100%;
  pointer-events: none;
  position: relative;
  transform: translateY(0);
}

@media only screen and (min-width: 860px) {
  .RS-intro--newsletter__artwork {
    width: 340px;
  }
}

@media only screen and (min-width: 1024px) {
  .RS-intro--newsletter__artwork {
    width: 440px;
    margin: 0 0 3vh;
  }
}

@media only screen and (min-width: 1280px) {
  .RS-intro--newsletter__artwork {
    width: 460px;
  }
}

@media only screen and (max-height: 430px) and (max-width: 1024px) {
  .RS-intro--newsletter__artwork {
    width: 46%;
  }
}

.RS-intro--newsletter__artwork-inner {
  position: relative;
}

.RS-intro-founder-background--industry {
  --RS-orbit-xy: calc(var(--RS-space-256)  + var(--RS-space-128));
  --RS-background-step: 12.5vh;
}

@media only screen and (min-width: 480px) {
  .RS-intro-founder-background--industry {
    --RS-orbit-xy: calc(var(--RS-space-256)  + var(--RS-space-256));
    --RS-background-step: 12.5vh;
  }
}

@media only screen and (min-width: 860px) {
  .RS-intro-founder-background--industry {
    --RS-orbit-xy: calc(var(--RS-space-256)  + var(--RS-space-128)  + var(--RS-space-64));
    --RS-background-step: 15vh;
  }
}

.RS-intro-founder-background-orbit--industry {
  z-index: 1;
}

.RS-intro-founder-background-orbit--industry:nth-of-type(2) {
  border: none;
  border: initial;
}

.RS-intro-founder-background__industry {
  width: 75%;
  z-index: 0;
  position: relative;
}

@media only screen and (min-width: 860px) {
  .RS-intro-founder-background__industry {
    width: 52.5%;
  }
}

.RS-intro-founder-background__industry--climate-tech {
  width: 42.5%;
}

.RS-intro-founder-background__industry--healthcare {
  width: 37.5%;
}

.RS-intro-founder-background__industry--cybersecurity {
  width: 27.5%;
}

.RS-intro-founder-background__industry--ai-infrastructure {
  left: -2%;
}

.RS-intro-founder-background__industry > span {
  animation: 4.5s linear infinite animFounderIndustryKv;
  display: block;
}

@keyframes animFounderIndustryKv {
  50% {
    transform: translate3d(0, 5%, 0);
  }
}

.RS-intro-founder-background__industry svg {
  overflow: visible;
}

.RS-intro-founder-background-icon--industry:nth-of-type(1) {
  backface-visibility: hidden;
  width: calc(var(--RS-orbit-xy) * .1);
  height: calc(var(--RS-orbit-xy) * .1);
  z-index: 2;
  position: absolute;
  top: 10%;
  left: 10%;
}

@media only screen and (min-width: 860px) {
  .RS-intro-founder-background-icon--industry:nth-of-type(1) {
    width: calc(var(--RS-orbit-xy) * .125);
    height: calc(var(--RS-orbit-xy) * .125);
    top: 8%;
    left: 8%;
  }
}

.RS-intro-founder-background-icon--industry:nth-of-type(1):before {
  width: 160%;
  height: 160%;
  top: -30%;
  left: -30%;
}

.RS-intro-founder-background-icon--industry:nth-of-type(1):after {
  content: "";
  width: 130%;
  height: 130%;
  background: var(--RS-light-grey-color);
  z-index: 0;
  border-radius: 50%;
  display: block;
  position: absolute;
  top: -15%;
  left: -15%;
}

.RS-intro-founder-background-icon--industry:nth-of-type(1) .RS-intro-founder-background-logo-inner {
  width: 65%;
  height: 65%;
  top: 17.5%;
  left: 17.5%;
}

.RS-intro-founder-background-icon--industry:nth-of-type(1) > .RS-intro-founder-background-logo-inner--industry-cybersecurity {
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
}

.RS-intro-founder-background-icon--industry:nth-of-type(1) > .RS-intro-founder-background-logo-inner--industry-ai-infrastructure {
  width: 70%;
  height: 70%;
  top: 15%;
  left: 15%;
}

.RS-intro-founder-background-icon--industry:nth-of-type(2) {
  bottom: 12%;
  right: 12%;
}

.RS-intro-founder-background-orbit--industry:nth-of-type(2) .RS-intro-founder-background-icon:nth-of-type(1) {
  top: auto;
  bottom: 12.75%;
  left: 12.75%;
  top: initial;
}

.RS-intro-founder-background-orbit--industry:nth-of-type(2) .RS-intro-founder-background-icon:nth-of-type(2) {
  top: 12.75%;
  left: auto;
  right: 12.75%;
  left: initial;
  bottom: auto;
  bottom: initial;
}

/*# sourceMappingURL=index.5b13cb68.css.map */
